<template>
  <v-card class="pa-4">
    <v-card-title>
      <h3 class="card-title">Parola Değiştirme</h3>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-text-field
          v-model="old_password"
          label="Eski Parola"
          type="password"
          :rules="[(v) => !!v || 'Eski Parola alanı boş bırakılamaz']"
          required
        >
        </v-text-field>
        <v-text-field
          v-model.lazy="password"
          :rules="[
            (v) => !!v || 'Yeni Parola alanı boş bırakılamaz',
            (v) =>
              v?.length >= 8 ||
              'Yeni Parola alanı en az 8 karakterden oluşmalıdır',
          ]"
          label="Yeni Parola"
          type="password"
          required
        ></v-text-field>
        <v-text-field
          v-model.lazy="password_confirmation"
          :rules="[
            (v) => !!v || 'Yeni Parola Tekrarı alanı boş bırakılamaz',
            (v) =>
              v?.length >= 8 ||
              'Yeni Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
            (v) =>
              v === password ||
              'Yeni Parola ve Yeni Parola Tekrarı alanları eşleşmiyor',
          ]"
          label="Yeni Parola Tekrar"
          type="password"
          required
        ></v-text-field>
        <v-checkbox
          v-model="remember_session"
          label="Kayıtlı Cihazlarda Oturumu Koru"
          color="primary"
          hide-details
          :hint="'Seçili olması durumunda oturumunuz sonlandırılmayacaktır.'"
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click="cancel">Sıfırla</v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="!isFormValid"
        @click="updatePassword"
        >Kaydet</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import PasswordService from "@/core/services/api/password.service";
import { DELETE_STATE } from "@/core/services/store/auth.module";

export default {
  name: "EditPassword",
  data() {
    return {
      old_password: "",
      password: "",
      password_confirmation: "",
      remember_session: true,
      isFormValid: false,
      loading: false,
      loader: null,
    };
  },
  methods: {
    async updatePassword() {
      this.loader = "loading";
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        await PasswordService.resetPassword(
          this.old_password,
          this.password,
          this.password_confirmation,
          this.remember_session
        );
        this.$toast.success("Parolanız başarıyla güncellendi.", {
          position: "bottom-right",
          duration: 2000,
        });
        if (!this.remember_session) {
          this.$store
            .dispatch(DELETE_STATE)
            .then(() => this.$router.push({ name: "login" }));
        }
        this.$refs.form.reset();
      } catch (error) {
        this.$toast.error(error.detail, {
          position: "bottom-right",
          duration: 2000,
        });
      }
      this[this.loader] = false;
      this.loader = null;
    },
    cancel() {
      this.$refs.form.reset();
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
    },
  },
};
</script>
