var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('h3',{staticClass:"card-title"},[_vm._v("Parola Değiştirme")])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"label":"Eski Parola","type":"password","rules":[(v) => !!v || 'Eski Parola alanı boş bırakılamaz'],"required":""},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}}),_c('v-text-field',{attrs:{"rules":[
          (v) => !!v || 'Yeni Parola alanı boş bırakılamaz',
          (v) =>
            v?.length >= 8 ||
            'Yeni Parola alanı en az 8 karakterden oluşmalıdır',
        ],"label":"Yeni Parola","type":"password","required":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"rules":[
          (v) => !!v || 'Yeni Parola Tekrarı alanı boş bırakılamaz',
          (v) =>
            v?.length >= 8 ||
            'Yeni Parola Tekrarı alanı en az 8 karakterden oluşmalıdır',
          (v) =>
            v === _vm.password ||
            'Yeni Parola ve Yeni Parola Tekrarı alanları eşleşmiyor',
        ],"label":"Yeni Parola Tekrar","type":"password","required":""},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('v-checkbox',{attrs:{"label":"Kayıtlı Cihazlarda Oturumu Koru","color":"primary","hide-details":"","hint":'Seçili olması durumunda oturumunuz sonlandırılmayacaktır.'},model:{value:(_vm.remember_session),callback:function ($$v) {_vm.remember_session=$$v},expression:"remember_session"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.cancel}},[_vm._v("Sıfırla")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":!_vm.isFormValid},on:{"click":_vm.updatePassword}},[_vm._v("Kaydet")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }