<template>
  <Card
    :title="title"
    :tabs="tabs"
    :tab-disabled="isChanged"
    :defaultTab="defaultTab"
    :color="'#1a1a27'"
    :height="'80px'"
    @change="handleTabChange"
    :showTopBar="true"
  >
    <template #item.informations>
      <EditProfile />
    </template>
    <template #item.password-reset>
      <EditPassword />
    </template>
  </Card>
</template>

<script>
import Card from "@/view/content/components/Card";
import EditProfile from "./EditProfile.vue";
import EditPassword from "./EditPassword";

export default {
  name: "Profile",
  components: {
    Card,
    EditProfile,
    EditPassword,
  },
  data() {
    return {
      tabs: [
        {
          value: "informations",
          text: "Profil Bilgileri",
        },
        {
          value: "password-reset",
          text: "Parola Değiştirme",
        },
      ],
      defaultTab: 0,
      title: "Profilim",
    };
  },
  beforeMount() {
    if (this.$route.query.tab) {
      this.defaultTab = this.$route.query.tab;
    }
  },
};
</script>

<style></style>
